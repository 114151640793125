// import { Provider }
import { Provider } from "@shopify/app-bridge-react";

const AppBridgeProvider = ({ Component, pageProps, children }) => {
  const host = new URLSearchParams(window.location.search).get("host");
  const embedded = new URLSearchParams(window.location.search).get("embedded");

  if (host && embedded === "1") {
    // we are in an iFrame
    const config = {
      apiKey: process.env.NEXT_PUBLIC_SHOPIFY_API_KEY,
      host,
      forceRedirect: true,
    };

    return <Provider config={config}>{children}</Provider>;
  }

  // else, we are not in an iFrame
  return <Component {...pageProps} />;
};

export default AppBridgeProvider;
