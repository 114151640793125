import "@/styles/globals.css";
import ClientOnly from "@/client/components/ClientOnly";
import { AppProvider as PolarisProvider } from "@shopify/polaris";
import enTranslations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/build/esm/styles.css";
import AppBridgeProvider from "@/client/auth/AppBridgeProvider";

export default function App({ Component, pageProps }) {
  return (
    <ClientOnly>
      <PolarisProvider i18n={enTranslations}>
        <AppBridgeProvider Component={Component} pageProps={pageProps}>
          <Component {...pageProps} />
        </AppBridgeProvider>
      </PolarisProvider>
    </ClientOnly>
  );
}
